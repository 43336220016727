<template>
    <div class="min-h-screen bg-[#F6F6F4] text-[#323333]">
        <div class="px-15 pb-15">
            <div class="flex items-center h-48">
                <div class="text-12">当前诊所：{{ clinicname }}</div>
                <router-link tag="div" :to="{ path: 'changeClinic', query: { isBack: 1 } }" class="text-14 font-bold text-[#FF9933] ml-4">切换 ></router-link>

                <div class="text-12 text-[#9D9D9D] ml-auto">工号：{{ username }}</div>
            </div>

            <div class="p-15 pb-30 bg-[#CF8C57] rounded-t-10 text-white">
                <div class="flex items-center">
                    <div class="text-16 leading-22 font-bold">{{ prescriptionDetail.username }}</div>
                    <div class="ml-5 px-3 text-10 leading-15 bg-[#FF9933] rounded-3" v-if="prescriptionDetail.appointmentnum">{{ prescriptionDetail.appointmentnum }}</div>

                    <router-link
                        tag="div"
                        :to="{ path: 'medicalHistory', query: { defaultName: prescriptionDetail.username, defaultPhone: prescriptionDetail.phone } }"
                        class="ml-auto text-12 flex-shrink-0 pl-10"
                    >
                        病历历史（{{ historyCount.medical }}）>
                    </router-link>
                </div>

                <div class="mt-10 flex items-center justify-between">
                    <div class="text-14 leading-20">
                        <span v-if="prescriptionDetail.gender">{{ prescriptionDetail.gender }}</span>
                        <span v-if="prescriptionDetail.age" class="ml-4">{{ prescriptionDetail.age }}岁</span>
                        <span v-if="prescriptionDetail.phone" class="ml-4">{{ prescriptionDetail.phone }}</span>
                    </div>
                    <div class="text-12">主账号：{{ prescriptionDetail.mainname || "-" }}</div>
                </div>
            </div>

            <div class="-mt-20 p-15 bg-white rounded-10">
                <div class="flex items-center justify-between">
                    <div class="text-16 leading-22 font-bold">病历/症状图片</div>
                    <div class="text-14 text-[#FF9933]" @click="handleShowCaseData">{{ caseData.show ? "收起" : "展开" }}</div>
                </div>

                <!-- 可展开收起部分 -->
                <div v-show="caseData.show">
                    <div class="mt-10 flex flex-wrap -mr-6 -mb-6" v-if="!emptyArray(prescriptionDetail.appointmentattchlist)">
                        <div
                            class="w-58 h-58 bg-[#F5F5F5] mr-6 mb-6 rounded-8 overflow-hidden"
                            v-for="(item, index) in prescriptionDetail.appointmentattchlist"
                            @click="handleCheckImg(prescriptionDetail.appointmentattchlist, index)"
                        >
                            <img class="w-full h-full object-contain" :src="item.url" />
                        </div>
                    </div>

                    <div class="mt-20" v-for="item in caseData.echartsList" :key="item.id">
                        <div class="text-16 leading-22 font-bold">{{ item.title }}</div>
                        <!-- echarts容器 -->
                        <div class="w-full h-132" :id="item.id" :ref="item.id"></div>
                    </div>

                    <div class="mt-20">
                        <div class="text-16 leading-22 font-bold">红外影像</div>

                        <div class="mt-10 flex flex-wrap -mr-6 -mb-6" v-if="!emptyArray(caseData.infraredImgList)">
                            <div class="w-58 mr-6 mb-6" v-for="(item, index) in caseData.infraredImgList">
                                <div class="w-58 h-58 bg-[#F5F5F5] rounded-8 overflow-hidden" @click="handleCheckImg(caseData.infraredImgList, index)">
                                    <img class="w-full h-full object-contain" :src="item.url" />
                                </div>
                                <div class="mt-4 text-14 text-center text-[#898989] line-clamp-1">{{ item.title }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-20">
                        <div class="mt-15 first:mt-0 flex text-14 leading-20" v-for="item in caseData.infoList" :key="item.key">
                            <div class="flex-shrink-0 w-56 h-20 text-[#898989] text-justify after:inline-block after:w-full">
                                {{ item.title }}
                            </div>
                            <div class="flex-shrink-0 w-14 text-[#898989]">
                                {{ item.title ? "：" : "" }}
                            </div>
                            <div class="flex-1 whitespace-pre-line">
                                {{ item.value || "-" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-10 px-15 py-10 bg-white rounded-10">
                <div class="flex items-center">
                    <div class="py-5 flex-1 flex items-center overflow-x-auto text-14 leading-20 mr-20 hiddenScrollBar whitespace-nowrap" ref="prescriptionContent">
                        <div v-for="item in prescriptionData.list" class="flex items-center relative group" :key="item.id">
                            <div class="w-1 h-10 bg-[#9D9D9D] mx-8 group-first:hidden"></div>
                            <div class="relative" :class="prescriptionData.activeId == item.id ? 'font-bold' : 'text-[#898989]'" @click="prescriptionData.activeId = item.id">
                                <div>{{ item.title }}</div>
                                <div
                                    class="absolute w-15 h-3 rounded-full -bottom-5 left-1/2 -translate-x-1/2"
                                    :class="prescriptionData.activeId == item.id ? 'bg-[#FF9933]' : ''"
                                ></div>
                            </div>
                        </div>

                        <div class="flex items-center">
                            <div class="w-1 h-10 bg-[#9D9D9D] mx-8"></div>
                            <van-icon color="#FF9933" size="calc(16rem/37.5)" name="plus" @click="handleAddPrescription" />
                        </div>
                    </div>

                    <router-link
                        tag="div"
                        class="flex-shrink-0 text-12 text-[#FF9933]"
                        :to="{ path: 'prescriptionHistory', query: { userid: userIdData.userid, customerid: userIdData.customerid } }"
                        v-if="prescriptionData.activeId != -1 && activePrescriptionData.ispay != 1"
                    >
                        <span>处方历史（</span>
                        <span class="text-[#EE0A23]">{{ historyCount.prescription }}</span>
                        <span>）></span>
                    </router-link>
                </div>

                <div v-if="prescriptionData.activeId == -1" class="mt-15">
                    <van-radio-group v-model="diagnosisData.seetype" direction="horizontal">
                        <van-radio :name="1" checked-color="#FF9933" class="mr-50">初诊</van-radio>
                        <van-radio :name="2" checked-color="#FF9933" class="mr-50">复诊</van-radio>
                        <van-radio :name="9" checked-color="#FF9933">网诊</van-radio>
                    </van-radio-group>

                    <div class="mt-15">
                        <div v-for="item in diagnosisData.list" :key="item.key" class="mt-10 first:mt-0 flex items-center relative">
                            <div class="flex-shrink-0 w-56 h-20 relative">
                                <div v-if="item.required" class="absolute -left-4 -top-4 text-[red]">*</div>
                                <div class="text-justify after:inline-block after:w-full">{{ item.title }}</div>
                            </div>
                            <div class="flex-shrink-0 w-14">
                                {{ item.title ? "：" : "" }}
                            </div>

                            <div class="flex-1 flex border-1 border-[#CECECE] min-h-60 rounded-10 overflow-hidden">
                                <van-field class="flex-1 self-center" v-model="item.value" rows="1" autosize type="textarea" />
                                <div
                                    v-if="item.isOption"
                                    @click="handleSetListPopup(item, 'prescription')"
                                    class="w-25 h-auto bg-[#FF9933] bg-opacity-10 flex items-center justify-center text-[#FF9933] font-bold"
                                >
                                    ...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="mt-15 -mx-3">
                    <div
                        class="pb-10 flex items-center"
                        v-if="prescriptionData.list.length > 2 && prescriptionData.activeId != prescriptionData.list[1].id && activePrescriptionData.ispay != 1"
                        @click="handleDelPrescription"
                    >
                        <van-icon name="clear" color="red" class="text-16" />
                        <div class="red ml-4 text-12 text-[red]">删除处方</div>
                    </div>

                    <div class="flex items-center">
                        <div
                            v-for="table in prescriptionTableData"
                            :key="table.key"
                            class="flex-shrink-0 text-center w-0 text-14 leading-20 ml-10 first:ml-0"
                            :style="'flex-grow:' + table.flex"
                        >
                            {{ table.title }}
                        </div>
                    </div>
                    <div class="mt-12 h-1 bg-[#EBEBEB]"></div>

                    <template v-if="!emptyArray(activePrescriptionData.druglist)">
                        <div class="mt-10" v-for="(item, index) in activePrescriptionData.druglist" :key="item.drugid">
                            <div class="flex items-center">
                                <div
                                    v-for="table in prescriptionTableData"
                                    :key="table.key"
                                    class="flex-shrink-0 w-0 text-14 leading-20 ml-10 first:ml-0"
                                    :style="'flex-grow:' + table.flex"
                                >
                                    <div class="flex items-center" v-if="table.key === 'drugname'">
                                        <div>{{ index + 1 }}.</div>
                                        <div v-if="drugTypeData[item.drugtype]" :style="'color:' + drugTypeData[item.drugtype].color">
                                            {{ drugTypeData[item.drugtype].label }}&nbsp;
                                        </div>
                                        <div class="flex-1">{{ item[table.key] }}</div>
                                    </div>

                                    <div v-else-if="table.key === 'drugnum'">
                                        <van-field
                                            input-align="center"
                                            class="py-6 px-8 text-12 leading-16 rounded-8 border-1 border-[#CECECE]"
                                            placeholder="选择数量"
                                            v-model="item[table.key]"
                                            :disabled="activePrescriptionData.ispay == 1"
                                            @click="handleSetDrugNumber(item)"
                                            readonly
                                        />
                                    </div>

                                    <div class="-ml-6" v-else-if="table.key === 'drugunit'">{{ item[table.key] }}</div>

                                    <van-field
                                        v-else-if="table.key === 'labelinfoname'"
                                        input-align="center"
                                        class="usageField py-6 px-5 text-12 leading-16 rounded-8 border-1 border-[#CECECE]"
                                        placeholder="选择用法"
                                        v-model="item[table.key]"
                                        readonly
                                        :disabled="activePrescriptionData.ispay == 1"
                                        @click="handleSetPicker('label', item)"
                                    >
                                        <div slot="right-icon">▼</div>
                                    </van-field>

                                    <div
                                        v-else-if="table.key === 'isowned'"
                                        class="w-full h-19 rounded-full relative"
                                        :class="item[table.key] == 1 ? 'bg-[#FF9933]' : 'bg-[#D2D2D2]'"
                                        :disabled="activePrescriptionData.ispay == 1"
                                        @click="handleChangeDrugOwned(item)"
                                    >
                                        <div
                                            class="w-17 h-17 rounded-full bg-white absolute top-1 transition-all"
                                            :class="item[table.key] == 1 ? 'left-[calc(100%-18px)]' : 'left-1'"
                                        ></div>
                                        <div class="text-12 leading-19 text-white" :class="item[table.key] == 1 ? 'ml-6' : 'ml-20'">自备</div>
                                    </div>

                                    <div class="w-20 h-20" v-else-if="table.key === 'content'" :class="activePrescriptionData.ispay == 1 ? 'opacity-0' : ''">
                                        <img class="w-full h-full object-contain" src="@/assets/trash_can.png" @click="handleDelDrug(item)" />
                                    </div>
                                </div>
                            </div>
                            <div class="ml-[calc(100%*85/321)] text-10 leading-14 -mb-5 mt-2 text-[#898989]" v-if="item.drugtype == 2 && item.drugnum && item.concentrationratio">
                                {{ getDrugTips(item) }}
                            </div>
                        </div>
                    </template>

                    <div class="py-20 border-b-1 border-[#EBEBEB]" v-if="activePrescriptionData.ispay != 1">
                        <div class="flex items-center justify-center" @click="handleAddDrug">
                            <div class="w-25 h-25 rounded-full flex items-center justify-center bg-[#FF9933]">
                                <van-icon name="plus" color="white" size="calc(19rem/37.5)" />
                            </div>
                            <div class="ml-6 text-16 text-[#FF9933] font-bold">添加药品</div>
                        </div>
                    </div>

                    <div class="mt-12">
                        <div class="text-12 leading-17 font-bold" v-html="activePrescriptionTips"></div>

                        <div class="flex mt-15">
                            <div class="flex items-center">
                                <div class="flex-shrink-0 w-56 h-20 text-justify after:inline-block after:w-full">剂量</div>
                                <div class="flex-shrink-0 w-14">：</div>

                                <div class="w-55 border-1 border-[#CECECE] min-h-30 h-full rounded-10 overflow-hidden">
                                    <van-field
                                        class="flex-1 self-center py-3 px-6"
                                        v-model="activePrescriptionData.dosage"
                                        input-align="center"
                                        rows="1"
                                        autosize
                                        type="number"
                                        :disabled="activePrescriptionData.ispay == 1"
                                        @input="handleLimitValue(['activePrescriptionData', 'dosage'], 1, 9999)"
                                        @change="getCalculateData()"
                                    />
                                </div>
                                <div class="ml-5">剂</div>
                            </div>

                            <div class="ml-auto flex items-center">
                                <div class="flex-shrink-0 w-56 h-20 text-justify after:inline-block after:w-full">制法</div>
                                <div class="flex-shrink-0 w-14">：</div>

                                <div class="w-55 border-1 border-[#CECECE] min-h-30 h-full rounded-10 overflow-hidden">
                                    <van-field
                                        class="flex-1 self-center py-3 px-6"
                                        v-model="activePrescriptionData.preparationName"
                                        input-align="center"
                                        :disabled="activePrescriptionData.ispay == 1"
                                        rows="1"
                                        autosize
                                        readonly
                                        @click="handleSetPicker('preparation')"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center mt-15">
                            <div class="flex-shrink-0 w-56 h-20 text-justify after:inline-block after:w-full">医嘱</div>
                            <div class="flex-shrink-0 w-14">：</div>

                            <div class="flex-1 flex border-1 border-[#CECECE] min-h-60 rounded-10 overflow-hidden">
                                <van-field
                                    :disabled="activePrescriptionData.ispay == 1"
                                    class="flex-1 self-center"
                                    v-model="activePrescriptionData.doctorremark"
                                    rows="1"
                                    autosize
                                    type="textarea"
                                />
                            </div>
                        </div>

                        <div class="flex items-center mt-15">
                            <div class="flex-shrink-0 w-56 h-20 text-justify after:inline-block after:w-full">是否复诊</div>
                            <div class="flex-shrink-0 w-14">：</div>

                            <van-radio-group v-model="activePrescriptionData.issubsequentvisit" direction="horizontal" :disabled="activePrescriptionData.ispay == 1">
                                <van-radio :name="0" checked-color="#FF9933" class="mr-25">需要复诊</van-radio>
                                <van-radio :name="1" checked-color="#FF9933">不需要复诊</van-radio>
                            </van-radio-group>
                        </div>

                        <div class="flex items-center mt-15">
                            <div class="flex-shrink-0 w-56 h-20 text-justify after:inline-block after:w-full">煎煮法</div>
                            <div class="flex-shrink-0 w-14">：</div>

                            <div class="flex-1 flex border-1 border-[#CECECE] min-h-60 rounded-10 overflow-hidden">
                                <van-field
                                    :disabled="activePrescriptionData.ispay == 1"
                                    class="flex-1 self-center"
                                    v-model="activePrescriptionData.decoctionmethod"
                                    rows="1"
                                    autosize
                                    type="textarea"
                                />
                                <div
                                    class="w-25 h-auto bg-[#FF9933] bg-opacity-10 flex items-center justify-center text-[#FF9933] font-bold"
                                    :class="activePrescriptionData.ispay == 1 ? 'grayscale' : ''"
                                    @click="handleSetListPopup({ title: '煎煮法', key: 'decoctionMethod' }, 'decoctionMethod')"
                                >
                                    ...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sticky left-0 bottom-0 z-20 w-screen h-55 bg-white flex items-center px-15 border-t-1 border-[rgba(0,0,0,0.1)]">
            <div class="__button mr-15" @click="$router.back()">返回</div>
            <div class="__button mr-15" @click="handleSubmit(0)">暂时保存</div>
            <div class="__button _primary" @click="handleSubmit(1)">完成提交</div>
        </div>

        <!-- 列表选择弹窗 -->
        <van-popup v-model="listPopupData.show" class="bg-transparent" @click-overlay="listPopupClick('cancel')">
            <div class="w-[335px] p-20 pb-25 rounded-10 bg-white">
                <div class="text-center font-bold text-16 leading-22">{{ listPopupData.title || "请选择" }}</div>

                <van-search
                    class="mt-10 p-0 rounded-11 overflow-hidden border-1 border-[#AAAAAA]"
                    v-model="listPopupData.tempKeyword"
                    :placeholder="'搜索' + (listPopupData.title || '')"
                    @search="handleSearchListPopup"
                    @clear="handleSearchListPopup"
                ></van-search>

                <div class="mt-20 text-center text-28 text-[#898989]" v-if="listPopupData.noData">暂无数据</div>
                <div class="mt-20 max-h-[66vh] overflow-y-auto" v-else>
                    <van-list v-model="listPopupData.loading" :finished="true" finished-text="已经到底啦~" @load="getListPopupOptions()">
                        <van-checkbox-group v-model="listPopupData.ids">
                            <van-cell-group :border="false">
                                <van-cell
                                    :border="false"
                                    class="bg-[#F4F4F4] rounded-11 mt-10 first:mt-0"
                                    v-for="item in listPopupData.list"
                                    :key="item.id"
                                    @click="handleListPopupCheck(item.id)"
                                >
                                    <template #title>
                                        <div class="text-14 leading-20">{{ item.desc }}</div>
                                    </template>
                                    <template #right-icon>
                                        <van-checkbox checked-color="#FF9933" :name="item.id" />
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-checkbox-group>
                    </van-list>
                </div>

                <div class="mt-25 px-15 flex">
                    <div class="__button mr-25" @click="listPopupClick('cancel')">取消</div>
                    <div class="__button _primary" @click="listPopupClick('confirm')">确定</div>
                </div>
            </div>
        </van-popup>

        <!-- 药品数量选择弹窗 -->
        <van-popup v-model="drugNumberData.show" class="bg-transparent" @click-overlay="drugNumberClick('cancel')">
            <div class="w-[335px] p-20 pb-25 rounded-10 bg-white">
                <div class="text-center font-bold text-16 leading-22">药品数量</div>

                <div class="flex flex-wrap -mr-12 -mb-10 mt-20">
                    <div
                        class="flex-shrink-0 w-[calc(25%-12px)] h-40 text-center leading-40 rounded-10 mr-12 mb-10"
                        v-for="item in drugNumberData.list"
                        :class="drugNumberData.activeNum === item ? 'bg-[#FF9933] text-white font-bold' : 'bg-[#F4F4F4]'"
                        @click="drugNumberData.activeNum = item"
                        :key="item"
                    >
                        {{ item }}
                    </div>
                </div>

                <van-field
                    @click="drugNumberData.activeNum = -1"
                    @input="handleLimitValue(['drugNumberData', 'custom'], 1, 9999)"
                    class="mt-15 bg-[#F4F4F4] rounded-10 border-1"
                    :class="drugNumberData.activeNum === -1 ? 'border-[#FF9933]' : 'border-transparent'"
                    v-model="drugNumberData.custom"
                    placeholder="请输入自定义药品数量"
                    type="number"
                />

                <div class="mt-25 px-15 flex">
                    <div class="__button mr-25" @click="drugNumberClick('cancel')">取消</div>
                    <div class="__button _primary" @click="drugNumberClick('confirm')">确定</div>
                </div>
            </div>
        </van-popup>

        <!-- 添加药品弹窗 -->
        <van-popup v-model="addDrugData.show" class="bg-transparent" @click-overlay="addDrugClick('cancel')">
            <div class="w-[335px] p-20 pb-25 rounded-10 bg-white">
                <div class="text-center font-bold text-16 leading-22">添加药品</div>

                <van-radio-group v-model="addDrugData.type" direction="horizontal" class="mt-20" @change="getDrugList">
                    <van-radio :name="1" checked-color="#FF9933" class="mb-15 mr-35">中药饮片</van-radio>
                    <van-radio :name="8" checked-color="#FF9933" class="mb-15 mr-35">小包装</van-radio>
                    <van-radio :name="2" checked-color="#FF9933" class="mb-15 mr-0">颗粒剂</van-radio>
                    <van-radio :name="3" checked-color="#FF9933" class="mb-15 mr-35">私人订制</van-radio>
                    <van-radio :name="5" checked-color="#FF9933" class="mb-15">商品</van-radio>
                </van-radio-group>

                <div class="flex items-center">
                    <van-search
                        @search="getDrugList"
                        @clear="getDrugList"
                        class="flex-1 h-35 text-14 leading-19 px-12 py-8 bg-[#F4F4F4] rounded-full mr-10"
                        v-model="addDrugData.keyword"
                        placeholder="请输入药品名称/简称/别名"
                    />
                    <div class="__button _primary w-60" @click="getDrugList">搜索</div>
                </div>

                <div class="mt-15 text-14 leading-20 text-[red]">直接点击药品进行选择</div>

                <div class="mt-10 rounded-10 border-1 border-[#CECECE] text-14 leading-20">
                    <div class="flex items-center">
                        <div
                            v-for="table in addDrugData.tableData"
                            :key="table.key"
                            class="flex-shrink-0 text-center w-0 py-8 text-[#898989] border-l-1 border-[#CECECE] first:border-none"
                            :style="'flex-grow:' + table.flex"
                        >
                            {{ table.title }}
                        </div>
                    </div>

                    <van-loading class="py-20" size="24px" vertical v-if="addDrugData.loading">加载中...</van-loading>
                    <div v-else-if="emptyArray(addDrugData.list)" class="py-50 text-[#898989] text-center border-t-1 border-[#CECECE]">暂无数据</div>
                    <div class="max-h-200 overflow-y-auto" v-else>
                        <div class="flex border-t-1 border-[#CECECE]" v-for="item in addDrugData.list" :key="item.id" @click="addDrugClick('confirm', item)">
                            <div
                                v-for="table in addDrugData.tableData"
                                :key="table.key"
                                class="flex-shrink-0 flex items-center justify-center text-center w-0 py-8 border-l-1 border-[#CECECE] first:border-none"
                                :style="'flex-grow:' + table.flex"
                            >
                                {{ item[table.key] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-25 px-15 flex">
                    <div class="__button" @click="addDrugClick('cancel')">关闭</div>
                </div>
            </div>
        </van-popup>

        <!-- 添加药品警告弹窗 -->
        <van-popup v-model="warnPopupData.show" class="bg-transparent" @click-overlay="warnPopupClick('cancel')">
            <div class="w-[335px] p-20 px-30 rounded-10 bg-white">
                <div class="text-center font-bold text-16 leading-22">提示</div>

                <div class="mt-20 text-14 leading-20 text-center">
                    <template v-if="warnPopupData.type === 1">
                        <span v-for="item in warnPopupData.list" :key="item.drugid">
                            <span>{{ item.drugname }}库存为</span>
                            <span class="text-[#EE0F25] font-bold">{{ item.curstock }}</span>
                            <span>{{ item.drugunit }}，</span>
                        </span>
                        <span>是否继续导入药方</span>
                    </template>

                    <template v-else-if="warnPopupData.type === 2">
                        <span>药品</span>
                        <span>{{ warnPopupData.list.map((item) => `【${item.drugname}】`).join("") }}</span>
                        <span>已添加，是否重新添加，还是在原有基础上增加数量？重新添加则会将原有已加入的药品全删除后添加，增加数量不删除原有药品</span>
                    </template>
                </div>

                <div class="mt-25 px-5 flex">
                    <template v-if="warnPopupData.type === 1">
                        <div class="__button mr-25" @click="warnPopupClick('cancel')">关闭</div>
                        <div class="__button _primary" @click="warnPopupClick('confirm')">确定</div>
                    </template>

                    <template v-else-if="warnPopupData.type === 2">
                        <div class="__button mr-10" @click="warnPopupClick('cancel')">关闭</div>
                        <div class="__button _primary mr-10" @click="warnPopupClick('cover')">重新添加</div>
                        <div class="__button _primary" @click="warnPopupClick('accumulation')">添加数量</div>
                    </template>
                </div>
            </div>
        </van-popup>

        <!-- 药品用量警告弹窗 -->
        <van-popup v-model="useNumWarnData.show" class="bg-transparent" @click-overlay="useNumPopupClick('cancel')">
            <div class="w-[335px] p-20 pb-25 rounded-10 bg-white">
                <div v-for="order in useNumWarnData.list" :key="order.title">
                    <div class="font-bold mt-4 first:mr-0">【{{ order.title }}】</div>
                    <div class="" v-for="item in order.warnList" :key="item.drugid">
                        <span>{{ item.drugname }}平均使用量</span>
                        <span class="font-bold text-[red]">{{ item.usenum }}{{ item.drugunit || "" }}</span>
                        <span>，本次下单为</span>
                        <span class="font-bold text-[red]">{{ item.drugnum }}{{ item.drugunit || "" }}</span>
                    </div>
                </div>
                <div class="mt-4">您确认是否提交此开方数据？</div>

                <div class="mt-25 px-15 flex">
                    <div class="__button mr-25" @click="useNumPopupClick('cancel')">取消</div>
                    <div class="__button _primary" @click="useNumPopupClick('confirm')">确定</div>
                </div>
            </div>
        </van-popup>

        <!-- 底部弹窗选择 -->
        <van-popup v-model="pickerData.show" position="bottom">
            <van-picker
                show-toolbar
                :default-index="pickerData.defaultIndex"
                value-key="itemname"
                :columns="pickerData.list"
                @confirm="(e) => pickerClick('confirm', e)"
                @cancel="pickerClick('cancel')"
                ref="bottomPicker"
            />
        </van-popup>
    </div>
</template>

<script>
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import {
    wxprescriptiondetail,
    wxmedicalhistorycount,
    wxtemper,
    wxbloodglucose,
    wxbloodpressure,
    wxinfraredimage,
    wxqrycommonphrases,
    wxqryprescriptiontemplatedetail,
    wxtriagedictionarydataqry,
    wxqrymedicaldrug,
    wxqryproductionfee,
    wxsubmitprescription,
} from "@/api/medical";
import { emptyArray } from "../../utils";
import { ImagePreview } from "vant";
import Decimal from "decimal.js";

// 存储echarts
const echartsData = {};

// watchList防抖 watch监听会触发两次，此处过滤
let watchListTimer = null;

// 基础订单数据 用于格式化与校准
class BaseOrderData {
    constructor(data = {}) {
        // 提交用数据
        this.orderid = data.orderid; // 订单ID
        this.ispay = data.ispay; // 是否已付款
        this.drugnum = data.drugnum; // 药品数量
        this.drugweight = data.drugweight; // 总重量
        this.makefee = data.makefee; // 制作费
        this.totalprice = data.totalprice; // 总金额
        this.dosage = data.dosage; // 剂量
        this.preparation = data.preparation; // 制法
        this.doctorremark = data.doctorremark; // 医嘱
        this.issubsequentvisit = data.issubsequentvisit; // 是否复诊
        this.decoctionmethod = data.decoctionmethod; // 煎服法
        this.druglist = data.druglist || []; // 药品列表

        // 额外数据
        this.preparationName = data.preparationName; // 制法名称
        this.calculateData = data.calculateData || {}; // 计算数据 用于计算制作费与数据展示
    }
}
// 基础药品数据
class BaseDrugData {
    constructor(data = {}) {
        // 不同的地方返回的id不一样 这里同步一下
        const id = data.drugid || data.infoid;

        this.drugid = id;
        this.infoid = id;
        this.drugname = data.drugname; // 药品名称
        this.drugtype = data.drugtype || data.druptype; // 药品类型
        this.drugtypename = data.drugtypename || data.druptypename; // 药品类型简称
        this.drugnum = data.drugnum || 1; // 饮片量 默认数量为1
        this.labelinfo = data.labelinfo; // 用法id
        this.labelinfoname = data.labelinfoname; // 用法
        this.isowned = data.isowned || 0; // 是否自备
        this.granulanum = data.granulanum; // 颗粒量
        this.standard = data.standard; // 规格
        this.concentrationratio = data.concentrationratio || data.concentrationRatio; // 溶缩比
        this.price = data.price; // 销售价
        this.usenum = data.usenum; // 药品平均用量

        // 额外数据
        this.drugunit = data.drugunit; // 药品单位
    }
}

export default {
    name: "prescription",
    components: {},
    data() {
        return {
            userIdData: {
                userid: null,
                customerid: null,
                appointmentid: null,
                apponintmentid: null,
                prescriptionid: null,
            },

            historyCount: {
                medical: 0, // 病历
                prescription: 0, // 处方
            },

            // 处方详情数据 部分展示放置在prescriptionData和diagnosisData内
            prescriptionDetail: {},

            // 病历数据
            caseData: {
                show: false,
                first: true,

                // echarts图表
                echartsList: [
                    { title: "体温图", id: "bodyTemperature" },
                    { title: "血糖图", id: "bloodSugar" },
                    { title: "血压图", id: "bloodPressure" },
                ],
                // 红外数据
                infraredImgList: [],
                infoList: [
                    { key: "exectime", title: "报告时间", value: "" },
                    { key: "reportcontext", title: "报告内容", value: "" },
                    { key: "suggest", title: "报告建议", value: "" },
                ],
            },

            // 诊断内容
            diagnosisData: {
                seetype: 0,
                list: [
                    { key: "complaint", title: "主诉", value: "", isOption: true, required: true },
                    { key: "medicalhistory", title: "病史", value: "", isOption: true },
                    { key: "pulsecondition", title: "脉象", value: "", isOption: true },
                    { key: "tongue", title: "舌象", value: "", isOption: true },
                    { key: "commonsigns", title: "常见体征", value: "" },
                    { key: "diagnose", title: "中医诊断", value: "", isOption: true, required: true },
                    { key: "westerndiagnostics", title: "西医诊断", value: "", isOption: true },
                ],
            },

            // 处方数据
            prescriptionData: {
                activeId: -1,
                count: 0,
                list: [{ id: -1, title: "病历与诊断" }],
            },
            prescriptionTableData: [
                { key: "drugname", title: "药名", flex: 80 },
                { key: "drugnum", title: "用量", flex: 70 },
                { key: "drugunit", title: "", flex: 5 },
                { key: "labelinfoname", title: "用法", flex: 70 },
                { key: "isowned", title: "自备", flex: 50 },
                { key: "content", title: "", flex: 15 },
            ],
            // 处方统计数据
            prescriptionStatisticData: [
                { key: "medicinalWeight", label: "饮片总重量" },
                { key: "singleGranuleWeight", label: "颗粒剂饮片总重量" },
                { key: "singleGranule", label: "颗粒剂总重量" },
                { key: "customNum", label: "私人定制总重量" },
                { key: "productNum", label: "商品数量" },
                { key: "makefee", label: "制作费", noHide: true, price: true },
                { key: "totalprice", label: "总计金额", noHide: true, price: true },
            ],
            // 药品类型对应数据
            drugTypeData: {
                1: { label: "饮", color: "#16f806" },
                2: { label: "颗", color: "#0d5be0" },
                3: { label: "私", color: "#f89406" },
                5: { label: "商", color: "#ff5722" },
                8: { label: "包", color: "#e008e7" },
            },

            // 单项列表选择弹窗数据
            listPopupData: {
                show: false,
                title: "",
                unselectedTips: "",
                ids: [],
                keyword: "",
                tempKeyword: "",

                list: [],
                noData: false,
                loading: false,

                resolve: null,
                reject: null,
            },

            // 药品数量选择弹窗数据
            drugNumberData: {
                show: false,
                custom: null, // 自定义数量
                activeNum: null, // 当前选择的数量，如果是自定义则为-1
                list: [1, 3, 5, 9].concat(new Array(11).fill(null).map((_, i) => (i + 2) * 5)).concat(90),

                resolve: null,
                reject: null,
            },

            // 字典数据
            dictionaryData: {
                preparation: [], // 处方制法
                label: [], // 药品用法
            },

            // 添加药品弹窗数据
            addDrugData: {
                show: false,
                type: 1, // 药品类型 目前是前端写死没配置字典 默认第一项type为1
                keyword: "", // 搜索关键字

                loading: false,
                list: [], // 数据列表
                // 表格数据
                tableData: [
                    { key: "drugname", title: "中药名称", flex: 80 },
                    { key: "standard", title: "规格", flex: 53 },
                    { key: "price", title: "价格 (元)", flex: 88 },
                    { key: "curstock", title: "库存", flex: 71 },
                ],

                resolve: null,
                reject: null,
            },
            // 警告弹窗数据
            warnPopupData: {
                show: false,
                type: 0, // 1：库存警告 2：重复警告
                list: [], // 警告数据列表

                resolve: null,
                reject: null,
            },

            // 底部单选弹窗数据
            pickerData: {
                show: false,
                defaultIndex: 0,
                list: [],

                resolve: null,
                reject: null,
            },

            // 药品用量警告弹窗
            useNumWarnData: {
                show: false,
                list: [],

                resolve: null,
                reject: null,
            },
        };
    },
    computed: {
        ...mapGetters(["username", "dispname", "clinicid", "clinicname"]),
        activePrescriptionData() {
            const { activeId, list } = this.prescriptionData;
            const activeItem = list.find((item) => item.id === activeId);

            if (activeItem == null) {
                return {};
            }

            return activeItem.data || {};
        },
        activePrescriptionTips() {
            const { calculateData, makefee, totalprice } = this.activePrescriptionData;

            const data = {
                makefee,
                totalprice,
                ...calculateData,
            };

            const tipsList = [
                { key: "total", left: "每剂", right: "味药", noHideen: true },
                { key: "medicinalWeight", left: "饮片总重量：", right: "" },
                { key: "singleGranuleWeight", left: "颗粒剂饮片总重量：", right: "" },
                { key: "singleGranule", left: "（颗粒剂总重量：", right: "）", noComma: true },
                { key: "customNum", left: "私人定制总重量：", right: "" },
                { key: "productNum", left: "商品数量：", right: "" },
                { key: "makefee", left: "制作费：", right: "", orange: true, noHideen: true, price: true },
                { key: "totalprice", left: "总计金额：", right: "", orange: true, noHideen: true, price: true },
            ];

            const fTipsList = tipsList.filter((item) => data[item.key] || item.noHideen);

            if (emptyArray(fTipsList)) {
                return "";
            }

            return fTipsList
                .map((item, index) => {
                    let value = data[item.key] || 0;

                    if (item.price) {
                        value = "￥" + value;
                    }

                    if (item.orange) {
                        value = `<span style="color: #FF9933">${value}</span>`;
                    }

                    return (index === 0 || item.noComma ? "" : "，") + item.left + value + item.right;
                })
                .join("");
        },
    },
    watch: {
        "activePrescriptionData.druglist": {
            handler() {
                clearTimeout(watchListTimer);
                watchListTimer = setTimeout(() => this.getCalculateData(), 200);
            },
            deep: true,
        },
    },
    methods: {
        emptyArray,
        /**
         * 初始化echarts
         */
        initEcharts() {
            this.caseData.echartsList.forEach((item) => {
                const dom = this.$refs[item.id] && this.$refs[item.id][0];

                if (dom) {
                    echartsData[item.id] = echarts.init(dom);
                }
            });
        },
        /**
         * 更新echarts数据
         */
        updateEcharts({ key, xAxisData, dataList, legendData }) {
            // 默认颜色rgb列表 第一项是高保真默认颜色，余下的是echarts的默认颜色，rgb格式是为了设置渐变色
            const defaultRgbColor = [
                "207, 140, 87",
                "84, 112, 198",
                "145, 204, 117",
                "250, 200, 88",
                "238, 102, 102",
                "115, 192, 222",
                "59, 162, 114",
                "252, 132, 82",
                "154, 96, 180",
                "234, 124, 204",
            ];

            const color = defaultRgbColor.map((rgb) => `rgb(${rgb})`);
            const linearColor = defaultRgbColor.map((rgb) => {
                return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 1, color: `rgb(${rgb})` },
                    { offset: 0, color: `rgba(${rgb}, 0.25)` },
                ]);
            });

            const keyUnitData = {
                bodyTemperature: "℃",
                bloodSugar: "mg/dL",
                bloodPressure: "mmHg",
            };

            echartsData[key].setOption({
                grid: {
                    top: "35%",
                    bottom: "20%",
                    right: "8%",
                    left: "10%",
                },
                color: color,
                xAxis: {
                    type: "category",
                    data: xAxisData || [],
                },
                legend: {
                    data: legendData || [],
                    textStyle: {
                        fontSize: "10",
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: true,
                    },
                    splitLine: false,
                    name: keyUnitData[key],
                },
                series: dataList.map((data, index) => ({
                    data: data,
                    name: legendData[index],
                    type: "line",
                    label: {
                        show: true,
                        position: "top",
                    },
                    areaStyle: {
                        color: linearColor[index],
                    },
                })),
            });
        },

        /**
         * 显示/隐藏病历数据
         */
        handleShowCaseData() {
            if (this.caseData.loading) {
                return;
            }

            this.caseData.show = !this.caseData.show;

            // 一次显示时获取数据
            if (this.caseData.first) {
                this.$nextTick(this.initEcharts);
                this.getCaseData();
                this.caseData.first = false;
            }
        },

        /**
         * 查看图片
         * @param url
         */
        handleCheckImg(list, index) {
            if (emptyArray(list)) {
                return;
            }

            ImagePreview({
                images: list.map((item) => item.url),
                startPosition: index,
            });
        },

        /**
         * 获取颗粒剂药品提示
         * @param item
         */
        getDrugTips(item) {
            return `颗：${Decimal.div(item.drugnum, item.concentrationratio).toDP(2).toNumber()}${item.drugunit || ""}`;
        },

        /**
         * 限制极限值
         * @param keys 数据key列表
         * @param min 最小值
         * @param max 最大值
         * @param dp 小数位
         */
        handleLimitValue(keys, min = -Infinity, max = Infinity, dp = 0) {
            let obj = this;
            const key = keys.pop();

            if (!emptyArray(keys)) {
                obj = keys.reduce((obj, cur) => obj[cur], obj);
            }

            const value = parseFloat(obj[key]);

            if (!isNaN(value)) {
                const newValue = Decimal.clamp(value, min, max).toDP(dp).toNumber();

                if (dp == 0 || obj[key] != newValue) {
                    obj[key] = newValue;
                }
            }
        },

        /**
         * 设置列表选择弹窗
         */
        async handleSetListPopup(item, type) {
            if (this.activePrescriptionData.ispay == 1) {
                return;
            }

            try {
                const desc = await new Promise((resolve, reject) => {
                    this.listPopupData.show = true;
                    this.listPopupData.title = "选择" + item.title;
                    this.listPopupData.unselectedTips = "请选择" + item.title;
                    this.listPopupData.ids = [];
                    this.listPopupData.key = item.key;
                    this.listPopupData.keyword = "";
                    this.listPopupData.tempKeyword = "";
                    this.getListPopupOptions({ init: true });

                    this.listPopupData.resolve = resolve;
                    this.listPopupData.reject = reject;
                });

                if (type === "prescription") {
                    item.value = (item.value ? item.value + "，" : "") + desc;
                } else if (type === "decoctionMethod") {
                    const value = this.activePrescriptionData.decoctionmethod;
                    this.activePrescriptionData.decoctionmethod = (value ? value + "，" : "") + desc;
                }
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 切换id选中
         * @param id
         */
        handleListPopupCheck(id) {
            const index = this.listPopupData.ids.indexOf(id);

            if (index === -1) {
                this.listPopupData.ids.push(id);
            } else {
                this.listPopupData.ids.splice(index, 1);
            }
        },

        /**
         * 列表弹窗选择确认
         * @param type cancel:'取消' confirm:'确认'
         */
        listPopupClick(type) {
            if (type === "cancel") {
                this.listPopupData.show = false;
                return this.listPopupData.reject("取消");
            }

            const { list, ids } = this.listPopupData;

            if (emptyArray(ids)) {
                this.$toast({ message: this.listPopupData.unselectedTips || "请选择" });
                return;
            }

            const option = list
                .filter((item) => ids.includes(item.id) && item.desc)
                .map((item) => item.desc)
                .join("，");

            this.listPopupData.show = false;
            this.listPopupData.resolve(option);
        },

        /**
         * 设置处方药品数量
         */
        async handleSetDrugNumber(item) {
            if (this.activePrescriptionData.ispay == 1) {
                return;
            }

            try {
                const num = await new Promise((resolve, reject) => {
                    this.drugNumberData.show = true;
                    this.drugNumberData.custom = null;
                    this.drugNumberData.activeNum = null;

                    this.drugNumberData.resolve = resolve;
                    this.drugNumberData.reject = reject;
                });

                item.drugnum = num;
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 处方药品数量确认
         * @param type cancel:'取消' confirm:'确认'
         */
        drugNumberClick(type) {
            if (type === "cancel") {
                this.drugNumberData.show = false;
                return this.drugNumberData.reject("取消");
            }

            const num = this.drugNumberData.activeNum === -1 ? this.drugNumberData.custom : this.drugNumberData.activeNum;

            if (num == null) {
                this.$toast({ message: "请选择药品数量" });
                return;
            }

            if (num <= 0) {
                this.$toast({ message: "药品数量不能少于0" });
                return;
            }

            this.drugNumberData.show = false;
            this.drugNumberData.resolve(num);
        },

        /**
         * 设置底部弹窗选择
         * @param type preparation：制法 label：用法
         */
        async handleSetPicker(type, item) {
            if (this.activePrescriptionData.ispay == 1) {
                return;
            }

            try {
                if (type === "preparation") {
                    this.pickerData.list = this.dictionaryData.preparation;
                    this.pickerData.defaultIndex = this.pickerData.list.findIndex((lItem) => lItem.itemcode == this.activePrescriptionData.preparation);
                } else if (type === "label") {
                    this.pickerData.list = [{ itemcode: -1, itemname: "无" }].concat(this.dictionaryData.label);
                    this.pickerData.defaultIndex = this.pickerData.list.findIndex((lItem) => lItem.itemcode == item.labelinfo);
                }

                const res = await new Promise((resolve, reject) => {
                    this.pickerData.show = true;
                    this.pickerData.resolve = resolve;
                    this.pickerData.reject = reject;

                    // 默认值只有第一次点开时生效，后续要同步位置只能手动设置
                    // 不过这里手动设置的话相当于忘记了上一次的滚动位置，如不需要直接删除整个nextTick即可
                    this.$nextTick(() => {
                        this.$refs.bottomPicker.setIndexes([this.pickerData.defaultIndex == -1 ? 0 : this.pickerData.defaultIndex]);
                    });
                });

                if (type === "preparation") {
                    this.activePrescriptionData.preparation = res.itemcode;
                    this.activePrescriptionData.preparationName = res.itemname;
                    this.getCalculateData();
                } else if (type === "label") {
                    if (res.itemcode === -1) {
                        item.labelinfo = null;
                        item.labelinfoname = "";
                    } else {
                        item.labelinfo = res.itemcode;
                        item.labelinfoname = res.itemname;
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 底部弹窗确认
         * @param type cancel:'取消' confirm:'确认'
         * @param value 选择的数据
         */
        pickerClick(type, value) {
            this.pickerData.show = false;
            if (type === "cancel") {
                this.pickerData.reject("取消");
            } else {
                this.pickerData.resolve(value);
            }
        },

        /**
         * 获取药品列表
         */
        async getDrugList() {
            try {
                this.addDrugData.loading = true;

                const { response_body: res } = await wxqrymedicaldrug({
                    username: this.username,
                    clinicid: this.clinicid,

                    obj: this.addDrugData.keyword,
                    drugtype: this.addDrugData.type,
                });

                this.addDrugData.list = res.list;
            } catch (error) {
                console.error(error);
            } finally {
                this.addDrugData.loading = false;
            }
        },

        /**
         * 添加药品
         */
        async handleAddDrug() {
            try {
                const item = await new Promise((resolve, reject) => {
                    this.addDrugData.show = true;
                    this.addDrugData.keyword = "";
                    this.addDrugData.list = [];
                    this.getDrugList();

                    this.addDrugData.resolve = resolve;
                    this.addDrugData.reject = reject;
                });

                this.activePrescriptionData.druglist.push(item);
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 导入药品 - 引用药品
         * @param importOrderData
         */
        async handleImportDrug(importOrderData) {
            try {
                this.$toast.loading({
                    message: "加载中...",
                    forbidClick: true,
                    duration: 0,
                });

                const { response_body: res } = await wxqryprescriptiontemplatedetail({
                    username: this.username,
                    clinicid: this.clinicid,
                    id: importOrderData.id,
                    type: importOrderData.type,
                });

                this.$toast.clear();
                if (emptyArray(res.data)) {
                    return;
                }

                const { druglist: drugList, decoctionmethod } = this.activePrescriptionData;

                // 拼接煎煮法
                this.activePrescriptionData.decoctionmethod = (decoctionmethod ? decoctionmethod + "，" : "") + res.decoctionmethod;

                const stockWarnList = [];
                const duplicateWarnList = [];
                const noDuplicateList = [];

                res.data.forEach((item) => {
                    item.drugid = item.infoid;

                    if (item.curstock < item.drugnum) {
                        stockWarnList.push(item);
                    }

                    if (drugList.some((jItem) => jItem.drugid == item.drugid)) {
                        duplicateWarnList.push(item);
                    } else {
                        noDuplicateList.push(item);
                    }

                    if (item.isowned == null) {
                        item.isowned = 0;
                    }
                });

                if (!emptyArray(stockWarnList)) {
                    try {
                        await new Promise((resolve, reject) => {
                            this.warnPopupData.show = true;
                            this.warnPopupData.type = 1;
                            this.warnPopupData.list = stockWarnList;

                            this.warnPopupData.resolve = resolve;
                            this.warnPopupData.reject = reject;
                        });
                    } catch (error) {
                        console.error(error);
                        return;
                    }
                }

                let type = "accumulation"; // 默认累加
                if (!emptyArray(duplicateWarnList)) {
                    try {
                        type = await new Promise((resolve, reject) => {
                            this.warnPopupData.show = true;
                            this.warnPopupData.type = 2;
                            this.warnPopupData.list = duplicateWarnList;

                            this.warnPopupData.resolve = resolve;
                            this.warnPopupData.reject = reject;
                        });
                    } catch (error) {
                        console.error(error);
                        return;
                    }
                }

                if (emptyArray(drugList) || type === "cover") {
                    this.activePrescriptionData.druglist = res.data;
                } else {
                    this.activePrescriptionData.druglist.forEach((item) => {
                        const duplicate = duplicateWarnList.find((jItem) => jItem.drugid == item.drugid);
                        if (duplicate) {
                            item.drugnum = (parseFloat(item.drugnum) || 0) + (parseFloat(duplicate.drugnum) || 0);
                        }
                    });

                    this.activePrescriptionData.druglist = drugList.concat(noDuplicateList.map((item) => new BaseDrugData(item)));
                }
            } catch (error) {
                this.$toast.clear();
                console.error(error);
            }
        },

        /**
         * 切换自备
         */
        handleChangeDrugOwned(item) {
            if (this.activePrescriptionData.ispay == 1) {
                return;
            }

            item.isowned = item.isowned == 1 ? 0 : 1;
        },

        /**
         * 删除药品
         */
        async handleDelDrug(item) {
            if (this.activePrescriptionData.ispay == 1) {
                return;
            }

            const { druglist: drugList } = this.activePrescriptionData;
            drugList.splice(
                drugList.findIndex((dItem) => dItem.drugid == item.drugid),
                1
            );
        },

        /**
         * 添加药品确认
         * @param type cancel:'取消' confirm:'确认'
         * @param item 确认时的列表数据
         */
        addDrugClick(type, item) {
            if (type === "cancel") {
                this.addDrugData.show = false;
                return this.addDrugData.reject("取消");
            }

            // 药品id字段不同 此处同步一下
            item.drugid = item.infoid;

            const { druglist: drugList } = this.activePrescriptionData;
            if (drugList.some((dItem) => dItem.drugid == item.drugid)) {
                this.$toast({ message: "该处方已添加了该药品" });
                return;
            }

            this.addDrugData.show = false;
            this.addDrugData.resolve(new BaseDrugData(item));
        },

        /**
         * 警告弹窗确认
         * @param type cancel:取消 confirm:确认 cover:覆盖 accumulation: 累加
         */
        warnPopupClick(type) {
            this.warnPopupData.show = false;

            if (type === "cancel") {
                return this.warnPopupData.reject("取消");
            }

            return this.warnPopupData.resolve(type);
        },

        /**
         * 用量弹窗确认
         * @param type cancel:取消 confirm:确认
         */
        useNumPopupClick(type) {
            this.useNumWarnData.show = false;

            if (type === "cancel") {
                return this.useNumWarnData.reject("取消");
            }

            return this.useNumWarnData.resolve();
        },

        /**
         * 添加处方
         */
        async handleAddPrescription({ init, data } = {}) {
            const id = Date.now() + "_" + (Math.random() * 10000).toFixed();

            if (this.prescriptionDetail.seetype) {
                data = Object.assign({ issubsequentvisit: this.prescriptionDetail.seetype == 1 ? 0 : 1 }, data);
            }

            const baseData = new BaseOrderData(data);

            if (!emptyArray(baseData.druglist)) {
                baseData.druglist = baseData.druglist.map((item) => new BaseDrugData(item));
            }

            // 设置默认的制法
            if (!emptyArray(this.dictionaryData.preparation)) {
                let preparationItem = this.dictionaryData.preparation[0];

                if (baseData.preparation) {
                    preparationItem = this.dictionaryData.preparation.find((item) => item.itemcode == baseData.preparation) || preparationItem;
                }

                baseData.preparation = preparationItem.itemcode;
                baseData.preparationName = preparationItem.itemname;
            }

            this.prescriptionData.list.push({ id, data: baseData, title: "处方" + ++this.prescriptionData.count });

            if (data) {
                // 获取计算数据
                this.getCalculateData(id, true);
            }

            if (!init) {
                this.prescriptionData.activeId = id;
                // 同时滚动到最右侧
                await this.$nextTick();
                this.$refs.prescriptionContent.scrollLeft = 99999;
            }
        },

        /**
         * 删除处方
         */
        async handleDelPrescription() {
            const { list, activeId } = this.prescriptionData;
            const index = list.findIndex((item) => item.id === activeId);
            const item = list[index];

            try {
                await this.$dialog.confirm({
                    message: `确认删除${item.title}吗？`,
                    confirmButtonColor: "red",
                });
            } catch (error) {
                console.error(error);
                return;
            }

            list.splice(index, 1);

            // 重新选择激活的数据
            this.prescriptionData.activeId = (list[index] || list[index - 1]).id;
        },

        /**
         * 计算药品相关数据
         * @param prescriptionId 处方id - 页面使用id 并非orderId
         * @param noMake 不计算制作费
         */
        async getCalculateData(prescriptionId, noMake = false) {
            const activeId = prescriptionId || this.prescriptionData.activeId;

            if (activeId === -1) {
                return;
            }

            const { data } = this.prescriptionData.list.find((item) => item.id === activeId);

            const drugList = data.druglist; // 药品列表
            const dosage = parseFloat(data.dosage); // 格式化剂量

            // 没有药品或剂量时 费用都为0
            if (emptyArray(drugList) || isNaN(dosage) || dosage <= 0) {
                data.totalprice = 0;
                data.makefee = 0;
                data.calculateData = {};
                return;
            }

            // 药品类型 1 中药饮片 8 小包装 2 颗粒剂 3 私人订制 5 商品
            const calculateData = {
                preparation: data.preparation, // 制法
                total: drugList.length, // 总数量

                totalWeight: Decimal(0), // 总重量
                medicinalWeight: Decimal(0), // 饮片总重量
                singleGranuleWeight: Decimal(0), // 颗粒剂饮片总重量
                singleGranule: Decimal(0), // 颗粒剂总重量
                customNum: Decimal(0), // 私人定制总重量
                productNum: Decimal(0), // 商品数量
                totalPrice: Decimal(0), // 总价格
            };

            drugList.forEach((item) => {
                const maxNum = parseFloat(item.onemaxnum);
                const num = parseFloat(item.drugnum);

                if (isNaN(num)) {
                    return;
                }

                if (!isNaN(maxNum) && maxNum !== 0 && maxNum < num) {
                    item.drugnum = maxNum;

                    this.$toast({ message: "当前药品单剂数量超出范围，已设置最大数量为" + maxNum });
                    throw "超出数量";
                }

                if (item.drugtype == 1 || item.drugtype == 8) {
                    calculateData.medicinalWeight = calculateData.medicinalWeight.add(num);
                }

                if (item.drugtype == 2) {
                    calculateData.singleGranuleWeight = calculateData.singleGranuleWeight.add(num);

                    const ratio = parseFloat(item.concentrationratio);
                    if (!isNaN(ratio) && ratio !== 0) {
                        calculateData.singleGranule = calculateData.singleGranule.add(Decimal(num).div(ratio));
                    }
                }

                if (item.drugtype == 3) {
                    calculateData.customNum = calculateData.customNum.add(num);
                }

                if (item.drugtype == 5) {
                    calculateData.productNum = calculateData.productNum.add(num);
                }

                // 非自备增加价格
                if (item.isowned != 1) {
                    calculateData.totalPrice = calculateData.totalPrice.add(Decimal.mul(num, parseFloat(item.price) || 0));
                }

                calculateData.totalWeight = calculateData.totalWeight.add(num);
            });

            // 保留1剂饮片和颗粒剂总重量用于计算制作费
            const medicinalWeight = calculateData.medicinalWeight.toDP(2).toNumber();
            const singleGranule = calculateData.singleGranule.toDP(2).toNumber();

            // 统一乘剂量倍数
            for (const key in calculateData) {
                if (key === "preparation" || key === "total") {
                    continue;
                }

                // 总价格保留四位小数含0
                if (key === "totalPrice") {
                    calculateData[key] = calculateData[key].mul(dosage).toFixed(4);
                    continue;
                }

                calculateData[key] = calculateData[key].mul(dosage).toDP(2).toNumber();
            }

            // // 制作费是否变化
            const isChange = ["preparation", "medicinalWeight", "singleGranule"].some((key) => data.calculateData[key] != calculateData[key]);
            data.calculateData = calculateData;

            if (noMake) {
                return calculateData;
            }

            // 自煎不用制作费
            if (data.preparation == this.dictionaryData.preparation[0].itemcode) {
                data.makefee = 0;
                data.totalprice = calculateData.totalPrice;
                return;
            }

            // 没有变化则不用重新获取制作费
            if (!isChange) {
                return;
            }

            try {
                const { response_body: res } = await wxqryproductionfee({
                    username: this.username,
                    clinicid: this.clinicid,
                    preparation: data.preparation,
                    soakFeet: 0,
                    replaceDosage: dosage,
                    medicinalWeight: medicinalWeight,
                    singleGranule: singleGranule,
                });

                data.makefee = res.price;
                data.totalprice = Decimal.add(calculateData.totalPrice, parseFloat(res.price) || 0).toFixed(4);
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 获取用户处方数据
         * 包括基础与病历信息
         */
        async getUserPrescriptionData() {
            try {
                const { response_body: res } = await wxprescriptiondetail(Object.assign({ username: this.username }, this.userIdData));

                if (res.brith == null || res.brith === "") {
                    try {
                        this.$toast.clear();

                        await this.$dialog.confirm({ message: "该患者未填写出生日期，确定是否为该患者开方？" });

                        this.$toast.loading({
                            message: "加载中...",
                            forbidClick: true,
                            duration: 0,
                        });
                    } catch (error) {
                        this.$router.back();
                    }
                }

                this.prescriptionDetail = res;

                // 诊断内容
                this.diagnosisData.seetype = parseInt(res.seetype) || null;
                this.diagnosisData.list.forEach((item) => {
                    item.value = res[item.key];
                });

                if (!emptyArray(res.orderlist)) {
                    res.orderlist.forEach((item) => {
                        this.handleAddPrescription({ init: true, data: item });
                    });
                } else {
                    this.handleAddPrescription({ init: true });
                }
            } catch (error) {
                return Promise.reject(error);
            }
        },

        /**
         * 获取病历或处方总数
         */
        async getHistoryCount() {
            try {
                const typeKeyData = [
                    { key: "medical", type: 1 },
                    { key: "prescription", type: 2 },
                ];

                await Promise.all(
                    typeKeyData.map(async (item) => {
                        const { response_body: res } = await wxmedicalhistorycount(
                            Object.assign(
                                {
                                    username: this.username,
                                    qrytype: item.type,
                                    name: this.prescriptionDetail.username,
                                },
                                this.userIdData
                            )
                        );
                        this.historyCount[item.key] = res.num;
                    })
                );
            } catch (error) {
                return Promise.reject(error);
            }
        },

        /**
         * 获取caseData的数据
         * 包括体温图、血糖图、血压图和红外影像数据
         */
        async getCaseData() {
            try {
                this.$toast.loading({
                    message: "加载中...",
                    forbidClick: true,
                    duration: 0,
                });

                await Promise.all(
                    [wxtemper, wxbloodglucose, wxbloodpressure, wxinfraredimage].map(async (fn, index) => {
                        const key = ["bodyTemperature", "bloodSugar", "bloodPressure", "infrared"][index];

                        try {
                            const { response_body: res } = await fn(Object.assign({ username: this.username }, this.userIdData));

                            if (key === "infrared") {
                                this.caseData.infraredImgList = res.attchlist;
                                this.caseData.infoList.forEach((item) => {
                                    item.value = res[item.key] || "";
                                });
                                return;
                            }

                            const dataKeys = {
                                bodyTemperature: [{ key: "hmts", length: "体温" }],
                                bloodSugar: [
                                    { key: "breakfastarry", length: "早餐" },
                                    { key: "dinnerarry", length: "午餐" },
                                    { key: "luncharry", length: "晚餐" },
                                    { key: "morningarry", length: "晨起" },
                                    // { key: "weightarry", length: "体重" }, // 数据有返回 但页面暂不展示
                                ],
                                bloodPressure: [
                                    { key: "breakfastarry", length: "收缩压" },
                                    { key: "dinnerarry", length: "舒张压" },
                                    { key: "luncharry", length: "脉搏(次/分)" },
                                ],
                            };

                            this.updateEcharts({
                                key,
                                xAxisData: res.datime,
                                dataList: dataKeys[key].map((item) => res[item.key] || []),
                                legendData: dataKeys[key].map((item) => item.length),
                            });
                        } catch (error) {
                            console.error(error);

                            if (key !== "infrared") {
                                // 如果获取数据失败 渲染空的图表防止页面空白
                                this.updateEcharts({
                                    key,
                                    xAxisData: res.datime,
                                    dataList: dataKeys[key].map((key) => res[key] || []),
                                    legendData: [],
                                });
                            }
                        }
                    })
                );

                this.$toast.clear();
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 搜索弹窗列表
         */
        handleSearchListPopup() {
            this.listPopupData.keyword = this.listPopupData.tempKeyword;
            this.getListPopupOptions({ init: true });
        },

        /**
         * 设置单项选择
         */
        async getListPopupOptions({ init } = {}) {
            if (init) {
                this.listPopupData.list = [];
                this.listPopupData.loading = false;
                this.listPopupData.noData = false;
            }

            const data = this.listPopupData;

            // key与短语type对应数据
            const keyTypeData = {
                complaint: "COMPLAINT", // 主诉
                medicalhistory: "MEDICAL_HISTORY", // 病史
                pulsecondition: "PULSE_TYPE", // 脉象（字典配置+短语配置）
                tongue: "TONGUE_TYPE", // 舌象（字典配置+短语配置）
                diagnose: "DIAGNOSE", // 中医诊断
                westerndiagnostics: "WESTERN_DIAGNOSE", // 西医诊断
                decoctionMethod: "DECOCTION_TYPE", // 煎服法
                empty8: "DRUG_NUM", // 药品数量
            };
            const type = keyTypeData[this.listPopupData.key];

            try {
                this.listPopupData.loading = true;
                const { response_body: res } = await wxqrycommonphrases({
                    keywords: data.keyword,
                    username: this.username,
                    qrytype: type,
                });

                data.loading = false;

                if (emptyArray(res.list)) {
                    data.noData = true;
                } else {
                    data.list = res.list.map((item) => ({ id: item.commonid, desc: item.phrasesdesc }));
                }
            } catch (error) {
                data.list = [];
                data.loading = false;
                data.noData = true;
                console.error(error);
            }
        },

        /**
         * 获取字典数据数据
         * @param type preparation：制法 label：用法
         */
        async getDictionaryData(type) {
            try {
                const typeCodeData = {
                    preparation: "PREPARATION_TYPE",
                    label: "DRUG_LABEL",
                };

                if (type == null) {
                    return await Promise.all(Object.keys(typeCodeData).map((type) => this.getDictionaryData(type)));
                }

                const { response_body: res } = await wxtriagedictionarydataqry({ username: this.username, dictcode: typeCodeData[type] });
                this.dictionaryData[type] = res.list;
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 保存提交
         * @param type 0：暂时保存 1：提交
         */
        async handleSubmit(type) {
            this.diagnosisData.list.forEach((item) => {
                if (item.required && (item.value == null || item.value === "")) {
                    this.$toast({ message: `请输入${item.title}` });
                    throw "校验失败 - 病历" + item.title;
                }
            });

            const orderlist = JSON.parse(
                JSON.stringify(
                    this.prescriptionData.list
                        .slice(1)
                        .filter((item) => !emptyArray(item.data.druglist))
                        .map((item) => Object.assign({ title: item.title }, item.data))
                )
            );

            if (type == 1 && emptyArray(orderlist)) {
                this.$toast({ message: `请添加处方` });
                throw "校验失败 - 处方为空";
            }

            const useNumWarn = [];
            orderlist.forEach((item) => {
                if (item.dosage == null || item.dosage === "") {
                    this.$toast({ message: `请输入${item.title}的剂量` });
                    throw "校验失败 - 剂量为空";
                }

                if (item.druglist.some((item) => item.drugtype == 2) && item.dosage % 2 !== 0 && item.dosage % 3 !== 0) {
                    this.$toast({ message: `${item.title}含有颗粒剂，剂量只能是2或者3的倍数` });
                    throw "校验失败 - 颗粒剂剂量错误";
                }

                item.drugnum = item.calculateData.total;
                item.drugweight = item.calculateData.totalWeight;

                item.druglist.forEach((drug) => {
                    drug.druptype = drug.drugtype;
                    drug.druptypename = drug.drugtypename;
                });

                const warnList = item.druglist.filter((drug) => drug.usenum && parseFloat(drug.drugnum) > parseFloat(drug.usenum) * 2);
                if (warnList.length) {
                    useNumWarn.push({
                        title: item.title,
                        warnList,
                    });
                }
            });

            if (useNumWarn.length) {
                try {
                    await new Promise((resolve, reject) => {
                        this.useNumWarnData.show = true;
                        this.useNumWarnData.list = useNumWarn;
                        this.useNumWarnData.resolve = resolve;
                        this.useNumWarnData.reject = reject;
                    });
                } catch (error) {
                    console.warn(error);

                    return;
                }
            }

            const data = {
                status: type,

                ...this.userIdData,
                execclinicid: this.clinicid,
                username: this.username,

                // 如果详情接口没有返回这些数据，则调取当前的诊所与医生数据
                doctor: this.prescriptionDetail.doctor || this.username,
                doctorname: this.prescriptionDetail.doctorname || this.dispname,
                clinicid: this.prescriptionDetail.clinicid || this.clinicid,

                customername: this.prescriptionDetail.username,
                sex: this.prescriptionDetail.gender,
                age: this.prescriptionDetail.age,
                phone: this.prescriptionDetail.phone,
                prescriptiondocid: this.prescriptionDetail.prescriptiondocid,

                appointmentattchlist: this.prescriptionDetail.appointmentattchlist,
                seetime: this.prescriptionDetail.seetime,
                seetype: this.diagnosisData.seetype,
                ...Object.fromEntries(this.diagnosisData.list.map((item) => [item.key, item.value])),
                orderlist,
            };

            try {
                this.$toast.loading({
                    message: type == 0 ? "保存中..." : "提交中...",
                    forbidClick: true,
                    duration: 0,
                });

                const { response_body: res } = await wxsubmitprescription(data);

                this.userIdData.prescriptionid = res.serviceId;

                if (res.code == 0) {
                    this.$toast({
                        message: type == 0 ? "保存成功" : "提交成功",
                        forbidClick: type == 1,
                    });

                    if (type == 1) {
                        setTimeout(() => {
                            this.$router.back();
                        }, 2000);
                    }
                } else {
                    this.$toast.clear();
                    this.$dialog.alert({
                        message: res.msg,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },

        async init() {
            try {
                this.$toast.loading({
                    message: "加载中...",
                    forbidClick: true,
                    duration: 0,
                });

                await this.getDictionaryData();
                await this.getUserPrescriptionData();
                await this.getHistoryCount();

                this.$toast.clear();
            } catch (error) {
                console.error(error);
                setTimeout(() => {
                    this.$router.back();
                }, 2000);
            }
        },
    },
    created() {
        this.$store.dispatch("hideOrShowNav", false);
        this.$store.commit("removeExclude", "prescription");

        // 将从query获取基础的四个id
        for (const key in this.userIdData) {
            this.userIdData[key] = this.$route.query[key];

            if (key === "appointmentid") {
                this.userIdData[key] = this.$route.query.apponintmentid;
            }
        }

        this.init();
    },
    activated() {
        const { importPrescriptionData, importOrderData } = this.$store.state.prescription;

        if (importPrescriptionData.status === 1) {
            this.diagnosisData.list.forEach((item) => {
                item.value = importPrescriptionData[item.key] || null;
            });

            this.$store.commit("initImportPrescriptionData");
        }

        if (importOrderData.status === 1) {
            this.handleImportDrug(importOrderData);
            this.$store.commit("initImportOrderData");
        }
    },
    beforeRouteLeave(to, from, next) {
        const keepAlivePath = ["/changeClinic", "/medicalHistory", "/prescriptionHistory"];

        if (!keepAlivePath.includes(to.path)) {
            this.$store.commit("addExclude", "prescription");
        }

        next();
    },
};
</script>
<style lang="scss">
.usageField {
    .van-field__right-icon {
        padding-left: 2px;
        padding-right: 6px;
        font-size: 6px;
        color: #d2d2d2;
    }
}
</style>
